import { Button, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import styles from "./index.module.scss";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DashboardService from "../../services/dashboard";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import Menu from "../AdminForms/Menu";
import Select from "react-select";
import { FilterIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { FilterIcon as FilterIconFilled } from "@heroicons/react/solid";
import {
  filterRowsDataInCols,
  getUniqueTableColValuesMap,
} from "../../common/utils";
import ExcelDownload from "../../components/Common/ExcelDownload";

const debounce = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const VugTransactions = (props) => {
  const { passFilteredData, gridData: propData, ...rest } = props;
  const [gridData, setData] = useState([]);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showVocadoMessagesModal, setshowVocadoMessagesModal] = useState(null);
  const [colFilter, setColFilter] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [colValsMapData, setColValsMapData] = useState(new Map());
  const formRef = useRef(null);
  const ref = useRef();

  const renderSelColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      const uniqVals = colValsMapData.get(uniqId);
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              // trigger={<FilterIcon width="16"  fill={props.filters[props.id] ? '#707070' : 'lightgray'} stroke={props.filters[props.id] ? '#707070' : ''} />}
              className={styles.menu}
            >
              <Select
                className={styles.filerInput}
                options={Array.from(uniqVals || new Set()).map((colVal) => ({
                  value: colVal,
                  label: colVal,
                }))}
                isMulti
                isClearable
                onChange={(val) => {
                  if (val.length) {
                    setColFilter((prev) => ({ ...prev, [uniqId]: val }));
                  } else {
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    });
                  }
                }}
                value={colFilter[uniqId]}
              />
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter, colValsMapData]
  );

  const renderColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              className={styles.menu}
            >
              <InputGroup className={styles.filerInput} size="sm">
                <InputGroup.Text id={uniqId}>
                  <SearchIcon width="16" />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby={uniqId}
                  onChange={debounce(({ target: { value } }) =>
                    setColFilter((prev) => ({
                      ...prev,
                      [uniqId]: value.trim(),
                    }))
                  )}
                  value={colFilter[uniqId]}
                />
                <Button
                  onClick={() =>
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    })
                  }
                >
                  <XIcon width="16" />
                </Button>
              </InputGroup>
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter]
  );

  useEffect(() => {
    const filterFn = async () => {
      const data = await filterRowsDataInCols(colFilter, gridData);
      setFilteredData(data);
    };
    filterFn();
  }, [gridData, colFilter]);

  const columns: Array<CustomColumn> = [
    {
      name: "filename",
      label: "FileName",
      showInExcel: true,
      headerClass: `text-nowrap`,
      width:70
    },
    {
      name: "aidYear",
      label: "AidYear",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:55
    },
    {
      name: "message_status",
      label: "Message Status",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:90
    },
    {
      name: "error_count",
      label: "ErrorCount",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:70
    },
    {
      name: "matched_count",
      label: "MatchedCount",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:90
    },
    {
      name: "numberOfRecords",
      label: "NumberOfRecords",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:110
    },
    {
      name: "success_count",
      label: "SuccessCount",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:95
    },
    {
      name: "unmatched_count",
      label: "UnmatchedCount",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:90
    },
    {
      name: "created_at",
      label: "Created At",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:90,
      sortable: true
    },
    {
      name: "updated_at",
      label: "Updated At",
      headerClass: `text-nowrap`,
      showInExcel: true,
      width:90,
      sortable: true
    },
  ];
  useEffect(() => {
    async function fetchVugTransactionsTable() {
      setIsLoading(true);
      const data = await DashboardService.getVugTransactions();
      setIsLoading(false);
      setData(
        data
          .map((d) => ({ ...d, created_at: new Date(d.created_at).valueOf() }))
          .sort((a, b) => b.created_at - a.created_at)
          .map((d) => ({
            ...d,
            created_at: new Date(d.created_at).toISOString(),
          }))
      );
    }
    fetchVugTransactionsTable();
    const data = [];
  }, []);

  // useEffect(() => {
  //   const getColVals = async () => {
  //     const data = await getUniqueTableColValuesMap(
  //       gridData,
  //       multiSelectFilterColsNames
  //     );
  //     console.log("data ==> ", data);
  //     setColValsMapData(data);
  //   };
  //   getColVals();
  // }, [gridData, multiSelectFilterColsNames]);

  // const clearAllFilters = useCallback(() => {
  //   setData([]);
  //   setColFilter({});
  //   setFilteredData([]);
  //   setColValsMapData(new Map());
  // }, []);

  return (
    <div className="">
      {/* <div className={styles.clear_all}>
        <Button
          className={styles.clear_all_btn}
          size="sm"
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
      </div> */}
      <div className={styles.download_btn}>
        <ExcelDownload data={gridData} filename={"vugs"} isVisible />
      </div>

      <div className="d-flex justify-content-center">
        <CustomTable
          parentClass={styles.fullTableWidth as any}
          isLoading={isLoading}
          rows={gridData}
          columns={columns}
          ref={ref}
          bordered
          rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
          rowClass="test"
          responsive
          striped
          title=""
          hasTableActions
          tableActionProps={{
            hasAddRow: false,
            hasSearch: false,
            hasDownload: false,
          }}
          pagination="default"
          lineHeight={"14px"}
          style={{ tableLayout: "fixed" }}
        />
      </div>
    </div>
  );
};
export default VugTransactions;
