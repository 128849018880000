import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createControlledClass,getControlledClasses,updateControlledClass } from "../../services/controltable";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import CustomTable , { CustomColumn }  from "../../components/CustomTable";
import { Button, Form, Stack } from "react-bootstrap";
import CustomField from "../AdminForms/CustomField"
import CustomModal from "../AdminForms/CustomModal";
import Modal from "../../components/Common/Modal"
import styles from "./index.module.scss";
import moment from "moment";
import { AppContext } from "../../common/AppProvider";




// const getParsedValue  = (value) =>{
//   try {
//    const parsedValue = JSON.stringify(value);
//    return parsedValue;
//   }catch(e){
//     return value.toString();
//   }
// }
const Classes = () => {

  const { data } = useContext(AppContext);
  // const columns: any = [
    // cellClass: `${styles.control_name_col}`,
    // cellClass: `${styles.control_value_col}`,
    // ,cellClass: `${styles.dictionary_col}`,
    // cellClass: `${styles.description_col}`,
    // cellClass: `${styles.created_at_col}`,
    // cellClass: `${styles.updated_at_col}`,
  // ];
  const columns: Array<CustomColumn> = [
    { name: "control_name", label: "Control Name",  showInExcel: true},
    { name: "control_value", label: "Control Value", showInExcel: true},
    { name: "dictionary", label: "Dictionary", showInExcel: true, renderRow: (value, row, index) => {
      return (<span
        onClick={()=> {
          if(value.length > 25){
            setShowDictionaryModal(value)
          }else{
            setShowDictionaryModal(null)
          }
        }}
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "150px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {value}
        </span>)
    } },
    { name: "description", label: "Description", showInExcel: true },
    { name: "created_at", label: "Created At", showInExcel: true },
    { name: "updated_at", label: "Updated At", showInExcel: true },
    {
      name: "Actions",
      label: "Actions",
      // headerClass: `${styles.text_ellipsis}`,
      headerClass: "text-center",
      renderRow: (_, row, idx) => rowActions(row, idx),
    }
  ];
  const ref = useRef();
  const [controldata, setControlData] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [showDictionaryModal, setShowDictionaryModal] = useState(false);
  const [currData, setCurrData] = useState({});
  const [currIndex, setCurrIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


    async function fetchControlTable() {
      setIsLoading(true);
      const data = await getControlledClasses();
      if (data.resData?.data) {
        setControlData(data.resData.data);
      }
      setIsLoading(false);
    }


    useEffect(() => {
      fetchControlTable();
      const data = [];
    }, []);
  

  const handleCurrValChange = (e) => {
    setCurrData((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  };

  const onDeleteRow = (index) => {
    setControlData((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const onEditRow = (index, row) => {
     setCurrData({ ...row });
     setCurrIndex(index);
     setShowModal("Edit");
  };

  const handleAddRow = () => {
    setShowModal("Add");
  };



  
  const rowActions = useCallback((row, index) => {
    return (
      <Stack className="flex-row justify-content-center" gap={2}>
        <Button
          title="Edit Row"
          size="sm"
          onClick={() => onEditRow(index, row)}
          className="bg-transparent border-0"
          disabled={(row.control_name == "SAFI_SSD_CONTROLS" || row.control_name == "SAFI_SSD_CONTROLS_UNEX" 
            || row.control_name == "GROUPER_ROLES" || row.control_name == "SAFI_TERM_DATES")}
        >
          <PencilIcon color="grey" width={18} />
        </Button>
        {/* {row.isNewRow && ( */}
        {/* <Button
          title="Delete Row"
          size="sm"
          onClick={() => onDeleteRow(index)}
          className="bg-transparent border-0"
        >
          <TrashIcon color="grey" width={18} />
        </Button> */}
        {/* )} */}
      </Stack>
    );
  }, []);

  const onModalClose = () => {
    setCurrData({});
    setCurrIndex(null);
    setShowModal(null);
  };

  // const onModalSave = useCallback(async () => {
  //   if (currIndex) {
  //     await updateControlledClass(currData)
  //     setControlData((prev) => {
  //       prev[currIndex] = { ...currData, modified: true } as any;
  //       return [...prev];
  //     });
  //   } else {
  //       await createControlledClass(JSON.stringify(currData))
  //       // console.log("", data);
  //       setControlData((prev: any) => {
  //       return [{ ...currData, isNewRow: true }, ...prev];
  //     });
  //   }
  //   onModalClose();
  // }, [currData, currIndex]);

  const onModalSave = useCallback(async () => {
    try {
      if (showModal === "Edit") {
        if (currIndex === null) {
          throw new Error("Missing edited row index!");
        }
        // const payload: any = { ...currData };
        const payload: any = {
          ...currData,
          updated_by: data?.login["custom:displayName"],
          updated_at: moment().toISOString(),
        };
        console.log(payload);
        delete payload.modified;
        delete payload.isNewRow;
        await updateControlledClass(payload)
        setControlData((prev) => {
          prev[currIndex] = { ...currData, modified: true } as any;
          return [...prev];
        });
        await fetchControlTable();
      } else {
        const payload: any = { ...currData };
        console.log(payload);
        delete payload.modified;
        delete payload.isNewRow;
        await createControlledClass(JSON.stringify(payload))
        // console.log("", data);
        setControlData((prev: any) => {
          return [{ ...currData, isNewRow: true }, ...prev];
        });
        await fetchControlTable();
      }
      onModalClose();
    } catch (err) {
      console.error(err);
      onModalClose();
    }
  }, [currData, currIndex, showModal]);
   


  


  return (
    <div className="control-table">
    <CustomTable
    parentClass={styles.fullTableWidth as any}
    isLoading={isLoading}
    rows={controldata}
    columns={columns}
    bordered={true}
    ref={ref}
    rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
    showIndex= {false}
    rowClass = {"test"}
    responsive = {true}
    striped = {true}
    title = {"Control Table"}
    hasTableActions = {true}
    pagination = {"default"}
    tableActionProps={{ onAddRow: handleAddRow }}
    // headerCellClass={styles.table1_header_cell}
    // bodyCellClass={styles.table_body_cell}
    // bodyRowClass={styles.table_row_cell}
  />
  <CustomModal
        title={`${showModal} Data`}
        show={showModal}
        onClose={onModalClose}
        onSave={onModalSave}
      >
        <Form>
          <Stack className="mb-3" gap={3}>
          {showModal !== "Edit" && (
          <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Control Name"
              name="control_name"
              type="text"
              value={currData["control_name"]}
              required
            />)}
            {showModal !== "Edit" && (
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Control Value"
              name="control_value"
              type="text"
              value={currData["control_value"]}
              required
            />)}
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Dictionary"
              name="dictionary"
              type="textarea"
              value={currData["dictionary"]}
              required
            />
             <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Description"
              name="description"
              type="text"
              value={currData["description"]}
              required
            />
          </Stack>
        </Form>
      </CustomModal>
      { showDictionaryModal && <Modal
            handleClose={() => setShowDictionaryModal(false)}
            config={{
              footer: false,
            title:"Dictionary Details",
            body: (
                <div className="word-break-all">{showDictionaryModal}</div>
              ),
            }}
          />}
    </div>
  );

};

export default Classes;
